import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import 'vant/lib/index.css'
import '@/assets/css/reset.css'
import 'lib-flexible/flexible'
import api from './api/api.js'
import { Field as VanField, Image as VanImage, Button as VanButton, Toast } from 'vant'
Vue.use(Toast)
Vue.use(VanField)
Vue.use(VanImage)
Vue.use(VanButton)
Vue.config.productionTip = false
Vue.prototype.$api = api
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
