import axios from 'axios'
const http = axios.create({
    // baseURL: "https://apigcp-uat.61b7cb.com",
    baseURL: process.env.NODE_ENV_BASEURL,
    timeout: 15000
})
http.interceptors.request.use((request) => {
    return request
})
http.interceptors.response.use((response) => {
    return response.data
})

export default http;
