<template>
  <div class="shareBg">
    <div class="vw_is" v-if="is_vx">
      <h1>请使用</h1>
      <h1>外部浏览器打开</h1>
    </div>
    <div class="sharePage">
      <div class="logo"></div>
      <div class="share_content">
        <div class="share_top_img">
          <img src="@/assets/images/share_top_img.png" alt="" />
        </div>
        <div class="share_bottom_content">
          <div class="share_bottom_content_title"></div>
          <div class="share_input_wrapper" v-show="showVerifi">
            <van-field
              v-model="inviteCode"
              placeholder="请输入邀请码"
              disabled
            />
            <van-field v-model.trim="verificaCode" placeholder="请输入验证码">
              <template #button>
                <VanImage
                  v-show="verificaImage"
                  :src="verificaImage"
                  width="88"
                  height="28"
                  fit="cover"
                  @click="getVerification()"
                />
              </template>
            </van-field>
          </div>
          <div class="button_wrapper" v-show="showVerifi">
            <VanButton
              type="primary"
              :loading="showLoad"
              loading-text="加载中..."
              @click="submit"
              >点击提交</VanButton
            >
          </div>
          <div class="download_page" v-show="!showVerifi">
            <div class="download_button">
              <VanButton type="primary" @click="downLoad()">立即下载</VanButton>
            </div>
          </div>
          <div class="share_three_pic"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "HomeView",
  data() {
    return {
      is_vx: /micromessenger/i.test(navigator.userAgent),
      verificaCode: "",
      inviteCode: "",
      showVerifi: true,
      verificaImage: "",
      verificaKey: "",
      showLoad: true,
      downLoadUrl: "",
      appKey: "2NSMW9", //Tinstall key
    };
  },
  mounted() {
    this.inviteCode =
      this.getQueryString("code") || this.getQueryString("share_code");
    this.getVerification();
  },
  methods: {
    getPlatform() {
      const u = navigator.userAgent;
      const isAndroid = u.indexOf("Android") > -1 || u.indexOf("Adr") > -1; //android终端
      const isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);
      let userAgent;

      if (isAndroid) {
        userAgent = 1;
      } else if (isiOS) {
        userAgent = 2;
      }
      return userAgent;
    },
    // 获取url参数
    getQueryString(name) {
      const reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)");
      const r =
        window.location.search.substr(1).match(reg) ||
        window.location.hash
          .substring(window.location.hash.search(/\?/) + 1)
          .match(reg);
      if (r != null) {
        return decodeURIComponent(r[2]);
      }
      return null;
    },
    async getVerification() {
      let res = await this.$api.postShareCaptcha();
      if (res.status == 200 && res.data) {
        this.verificaImage = res.data?.url?.img; //图形验证码
        this.verificaKey = res.data?.url?.key; //图形验证码Key
        this.showLoad = false;
      } else {
        this.showLoad = false;
      }
    },
    async submit() {
      if (this.verificaKey && this.inviteCode && this.verificaCode) {
        this.showLoad = true;
        const sendData = {
          share_code: this.inviteCode,
          key: this.verificaKey,
          captcha: this.verificaCode,
          share_platform: this.getPlatform(),
        };
        let res = await this.$api.postShareSubmit(sendData);
        if (res.status == 200 && res.data) {
          this.downLoadUrl = res.data.downUrl ? res.data.downUrl : "";
          this.showVerifi = false;
          this.showLoad = false;
        } else {
          this.$toast(res.message);
          this.getVerification();
        }
      } else {
        this.$toast("验证码不能为空");
      }
    },
    downLoad() {
      this.$toast("正在下载…，请勿重复点击");
      this.tInstallOpen();
    },
    tInstallOpen() {
      let w;
      w = window.open("");
      w.document.body.innerHTML = "正在加载中，请稍等...";
      try {
        // eslint-disable-next-line no-undef
        TInstall.setHost("https://www.floweruninstall.com");
        // eslint-disable-next-line no-undef
        let params = TInstall.parseUrlParams();
        // let params = {
        //   code: this.inviteCode,
        // };
        // params.chan_key = window.chan_key;
        // params.time_xytick = "" + Math.round(new Date() / 1000);
        // eslint-disable-next-line no-undef
        TInstall.getInstall(this.appKey, params, () => {
          if (this.downLoadUrl.includes("http")) {
            const urlObj = new URL(this.downLoadUrl);
            if (this.getPlatform() === 2) {
              urlObj.search += urlObj.search.startsWith("?")
                ? "&one_share_code="
                : "?one_share_code=";
              urlObj.search += this.inviteCode;
            }
            w.location = urlObj.href;
          }
        });
      } catch (e) {
        // console.log(e);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.shareBg {
  width: 100%;
  min-height: 100vh;
  background-image: url("@/assets/images/share_bg.png");
  background-repeat: no-repeat;
  background-size: 100%;
  padding-top: 30px;
  .sharePage {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .logo {
    width: 200px;
    height: 57px;
    background-image: url("@/assets/images/logo.png");
    background-repeat: no-repeat;
    background-size: 100%;
    margin-bottom: 15px;
  }
  .share_content {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 34px;
    .share_top_img {
      width: 295px;
      height: 287px;
      position: absolute;
      img {
        width: 100%;
        height: 100%;
        display: block;
      }
    }
    .share_bottom_content {
      margin-top: 144px;
      width: 335px;
      // min-height: 492px;
      border-radius: 10px;
      background: rgba($color: #fff, $alpha: 0.6);
      padding-top: 153px;
      display: flex;
      flex-direction: column;
      align-items: center;
      .share_bottom_content_title {
        width: 313px;
        height: 64px;
        background-image: url("@/assets/images/title.png");
        background-repeat: no-repeat;
        background-size: 100%;
        margin-bottom: 12px;
      }
      .share_input_wrapper {
        ::v-deep .van-cell {
          display: flex;
          width: 232px !important;
          height: 40px !important;
          flex-shrink: 0;
          align-items: center;
          gap: 10px;
          border-radius: 56px;
          border: 0.5px solid #000;
          margin-bottom: 20px;
          background: initial;
          input {
            color: #000 !important;
            text-align: center;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            -webkit-text-fill-color: #000 !important;
            &::placeholder {
              font-family: STKaiti;
              color: #000 !important;
              font-size: 16px;
            }
          }
          .van-image {
            display: flex;
          }
        }
      }
      .button_wrapper {
        margin: 15px 0 0 0;
        ::v-deep .van-button {
          display: flex;
          width: 150px;
          height: 40px;
          justify-content: center;
          align-items: center;
          border-radius: 6px;
          background: #d9d9d9;
          border: none;
          color: #000;
          text-align: center;
          font-family: STKaiti;
          font-size: 20px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
        }
      }
    }
    .share_three_pic {
      width: 246px;
      height: 106px;
      background-image: url("@/assets/images/pic_3.png");
      background-repeat: no-repeat;
      background-size: 100%;
      margin: 15px 0 20px 0;
    }
    .download_page {
      margin: 20px 0 20px 0;
      ::v-deep .van-button {
        display: flex;
        width: 150px;
        height: 39.344px;
        justify-content: center;
        align-items: center;
        border-radius: 7.377px;
        border: 1px solid rgba(0, 0, 0, 0.36);
        background: #d9d9d9;
        color: #000;
        text-align: center;
        font-family: STKaiti;
        font-size: 24.59px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
    }
  }
}
@media screen and (max-height: 600px) {
  // .share_page {
  //   padding-top: 35px !important;
  // }
  // .share_input_wrapper {
  //   margin-top: 50px !important;
  // }
  // .download_button {
  //   margin: 60px 0 0 0 !important;
  // }
}
</style>
